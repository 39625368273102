import React, { useEffect } from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'

import Layout from "../components/landingLayout"
import PlataformaFirstMob from '../images/plataforma-info.png'
import CelImg2 from '../images/cel-img-2.png'
import CelImg1 from '../images/cel-img-1.png'
import CelImg3 from '../images/cel-img-3.png'
import CelImg4 from '../images/cel-img-4.png'
import Comma from '../images/comma.png'
import PlataformaFirst from '../images/plataforma-first.png'
import Ellipse from '../images/ellipse.png'
import Ellipse1 from '../images/ellipse1.png'
import Ellipse2 from '../images/ellipse-2.png'
import Ellipse3 from '../images/ellipse3.png'
import Ellipse4 from '../images/ellipse4.png'
import './index.scss'
import './landing-plataforma.scss'
import trackEvent from "../analyticsHelpers"

declare global { interface Window { analytics: any; } }

function LandingPlataforma() {
 
  useEffect(() => {
    trackEvent("sitenovo-landing-plataforma-visualizou-site")
  }, [])

  return (
    <Layout isWhiteLogo extraClass>
      <GatsbySeo
        title='Theia | Uma plataforma inteira dedicada para sua gravidez'
        description='Conheça o programa que cuida de você semana a semana durante a gravidez'
        language='pt-br'
        canonical='https://blog.theia.com.br/plataforma'
        openGraph={{
          type: 'website',
          url: 'https://blog.theia.com.br/plataforma',
          title: 'Theia | Uma plataforma inteira dedicada para sua gravidez',
          description: 'Conheça o programa que cuida de você semana a semana durante a gravidez',
          images: [
            {
              url: 'https://theia.com.br/theia.png',
              width: 250,
              height: 367,
              alt: 'Og Imagem Alt',
            },
          ],
        }}
        metaTags={[{
          name: 'dc:title',
          content: 'Theia | Uma plataforma inteira dedicada para sua gravidez'
        }, {
          name: 'dc:description',
          content: 'Conheça o programa que cuida de você semana a semana durante a gravidez'
        }, {
          name: 'dc:language',
          content: 'BR',
        }, {
          name: 'reply-to',
          content: 'suporte@theia.com.br',
        }, {
          name: 'author',
          content: 'Theia',
        }, {
          name: 'description',
          content: 'A Theia tem uma filosofia de parto humanizado pensada na melhor experiência para você',
        }, {
          name: 'keywords',
          content: 'gravidez, sintomas de gravidez, testes de gravidez, ginecologistas, obstetras, pré-natal, pre natal, semanas de gestacao, ensaio gestante, exame de gravidez, mulher gravida, ginecologistas unimed,semanas gestacionais, gravidez ectopica, consulta pre natal, consulta pré natal, parto, períneo, parto normal, parto humanizado, puerpério, cesaria, cesariana, parto cesaria, parto cesária',
        },
        , {
          property: 'twitter:card',
          content: 'summary_large_image',
        }
      ]}
      />
      <div className="plataforma-main">
        <section className="pb-20 first-section-plataforma relative">
            <div className="md:flex md:flex-col lg:pl-20 lg:pr-20">
              <div className="flex flex-col lg:flex-row roda-content text-center pt-20 lg:pt-32 lg:text-left lg:px-0  w-full lg:justify-around">
                <div className="lg:w-2/4 flex flex-col">
                  <h1 className="font-medium">
                    Uma plataforma inteira
                  </h1>
                  <p className="plataforma-main-p">dedicada para sua gravidez:</p>
                  <div className="lg:w-2/4">
                    <img src={PlataformaFirstMob} alt="" className="mt-12 lg:hidden block ml-auto plataforma-img-mob" />
                  </div>
                  <div className="mx-5 lg:px-0 relative mt-8">
                    <span className="vertical-line-plat" />
                    <div className="flex w-full lg:w-3/4 mt-6">
                      <span className="first-bullet bullet flex-none z-10" />
                      <p className="plataforma-bullet ml-8">Programa que <b>te indica o que fazer</b> a cada semana</p>
                    </div>
                    <div className="flex w-full lg:w-3/4 mt-6">
                      <span className="second-bullet bullet flex-none z-10" />
                      <p className="plataforma-bullet ml-8">Conteúdo <b>especializado</b></p>
                    </div>
                    <div className="flex w-full lg:w-3/4 mt-6">
                      <span className="third-bullet bullet flex-none z-10" />
                      <p className="plataforma-bullet ml-8">
                        Rede integrada de <b>profissionais multidisciplinares</b>, disponíveis por <b>vídeo ou no consultório</b>
                      </p>
                    </div>
                  </div>
                  <div className="mt-12 px-5 lg:px-0">
                    <a href="https://app.theia.com.br/boas-vindas" className="button-plataforma" onClick={() => trackEvent("converteu plataforma")}>
                      Começar programa
                    </a>
                  </div>
                </div>
                
                <div className="lg:w-2/4 lg:block hidden">
                  <img src={PlataformaFirst} alt="" className="app-img" />
                </div>
              </div>
            </div>
          </section>
          <section className="second-section-plataforma">
            <div>
              <h2 className="second-title-plataforma">Quem usa, ama!</h2>
              <div className="flex flex-col mt-12 lg:mt-20">
                <div className="flex flex-col lg:flex-row">
                  <div className="comment">
                    <img src={Comma} />
                    <p>
                      O acolhimento, atenção e carinho que recebi da Theia tem sido como um regador combustível de amor e esperança. Me sinto cada vez melhor. Não tem nome isso que a Theia faz com a gente, não se define.
                    </p>
                    <span className="line" />
                    <span>S. S. 12 semanas</span>
                  </div>
                  <div className="comment second">
                    <img src={Comma} />
                    <p>
                    Foi tudo perfeito! Nunca me senti tão bem em escolher o plano da Theia! Estou muito feliz com tudo que essa equipe proporciona, realmente estaria bem perdida sem o apoio de vocês!
                    </p>
                    <span className="line" />
                    <span>A. M. 7 semanas</span>
                  </div>
                </div>
                <span className="divider" />
              </div>
              <h2 className="second-title-plataforma second">
              Veja como nossa plataforma vai te ajudar a cuidar da sua gravidez
              </h2>
            </div>
          </section>
          <section className="third-section-plataforma">
            <div className="flex w-full flex-col lg:flex-row">
              <div className="flex-1 mb-8">
                <p className="top top-pink">
                  Acompanhe a sua rotina de consultas
                </p>
                <div className="flex items-center justify-center relative" >
                  <img src={Ellipse} alt="" className="flex-none ellipse ellipse-0" />
                  <img src={Ellipse1} alt="" className="flex-none ellipse ellipse-1" />
                  <img src={CelImg1} alt="" className="img-1 flex-none" />
                </div>
              </div>
              <div className="flex-1 mb-8">
                <p className="top top-purple">
                  Receba conteúdos semana a semana
                </p>
                <div className="flex items-center justify-center relative">
                  <img src={Ellipse} alt="" className="flex-none ellipse ellipse-2" />
                  <img src={Ellipse1} alt="" className="flex-none ellipse ellipse-3" />
                  <img src={CelImg2} alt="" className="img-1" />
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col lg:flex-row">
              <div className="flex-1 mb-8">
                <p className="top top-purple">
                  Navegue pelos trimestres da sua gestação
                </p>
                <div className="flex items-center justify-center relative">
                  <img src={Ellipse3} alt="" className="flex-none ellipse ellipse-4" />
                  <img src={Ellipse2} alt="" className="flex-none ellipse ellipse-5" />
                  <img src={CelImg3} alt="" className="img-1" />
                </div>
              </div>
              <div className="flex-1">
                <p className="top top-pink">
                  Agende suas consultas com rapidez
                </p>
                <div className="flex items-center justify-center relative">
                  <img src={Ellipse4} alt="" className="flex-none ellipse ellipse-6" />
                  <img src={Ellipse} alt="" className="flex-none ellipse ellipse-7" />
                  <img src={CelImg4} alt="" className="img-1" />
                </div>
              </div>
            </div>
            <div className="mt-12">
              <a href="https://app.theia.com.br/boas-vindas" className="button-plataforma" onClick={() => trackEvent("converteu plataforma")}>
                Começar programa
              </a>
            </div>
        </section>
      </div>
    </Layout>
  )
}

export default LandingPlataforma
